import React, { useEffect } from "react";
import $ from "jquery";

function CircleProgressContainer() {
  useEffect(() => {
    $(document).ready(function () {

      //================ Progress circle Bar ================\\
      function updateProgressCircle() {
        const progressElement = document.querySelector(
          ".ss-progress_circle_bar"
        );
        const scrollToTopElement = document.querySelector(".scroll-to-top");
        const percentageText = document.querySelector(".percentage-text");
        const totalHeight = document.body.scrollHeight - window.innerHeight;

        let progress = (window.pageYOffset / totalHeight) * 283;
        progress = Math.min(progress, 283);
        progressElement.style.strokeDashoffset = 283 - progress;

        // Calculate percentage and update the text
        let percentage = Math.min(
          Math.round((window.pageYOffset / totalHeight) * 100),
          100
        );
        percentageText.textContent = percentage + "%";

        // Show/hide scroll to top button
        if (
          window.innerHeight + window.pageYOffset >=
          document.body.offsetHeight
        ) {
          scrollToTopElement.style.opacity = "1";
        } else {
          scrollToTopElement.style.opacity = "0";
        }
      }

      function scrollToTop() {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }

      document
        .querySelector(".scroll-to-top")
        .addEventListener("click", scrollToTop);
      updateProgressCircle();
      window.addEventListener("scroll", updateProgressCircle);
      window.addEventListener("resize", updateProgressCircle);
    });
  });

  return (
    <div className="ss-progress-circle-container">
      <svg className="progress-circle" viewBox="0 0 100 100">
        <circle className="progress-background" cx="50" cy="50" r="45"></circle>
        <circle
          className="ss-progress_circle_bar"
          cx="50"
          cy="50"
          r="45"
          style={{ strokeDashoffset: 283 }}
        ></circle>
      </svg>
      <div className="percentage-text">0%</div>
      <div className="scroll-to-top">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M12 19V5M5 12l7-7 7 7"></path>
        </svg>
      </div>
    </div>
  );
}

export default CircleProgressContainer;
