import React from "react";
import { useEffect } from "react";
import i18n from "../../i18n/i18n";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import MainRoutes from "./MainRoutes";

function LanguageRoutes() {
  const { lang } = useParams();
  const {
    i18n: { changeLanguage },
  } = useTranslation();
  const handleChangeLanguage = (newLanguage) => {
    changeLanguage(newLanguage);
  };
  useEffect(() => {
    handleChangeLanguage(lang);
  }, [lang]);
  return (
    <>
      <MainRoutes />
    </>
  );
}

export default LanguageRoutes;
