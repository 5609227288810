import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import FooterLanguagesRow from "../FooterLanguagesRow/FooterLanguagesRow";
import { useParams } from "react-router-dom";

function LanguageHandler() {
  const {
    i18n: { changeLanguage },
  } = useTranslation();
  const { lang } = useParams();
  const handleChangeLanguage = () => {
    changeLanguage(lang);
  };
  useEffect(() => {
    handleChangeLanguage();
  },[lang]);
  return <FooterLanguagesRow />;
}

export default LanguageHandler;
