import React from "react";
import { Link, useParams } from "react-router-dom";
import useBreakpoint from "use-breakpoint";
import ImgTag from "../Elements/ImgTag";
import { logoImgSourceURL } from "../../assets/js/Constants/localImageURL";
import { BREAKPOINTS } from "../../assets/js/Constants/constants";
import { t } from "i18next";
import $ from "jquery";

function Header() {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const paramDetails = useParams();
  const { lang } = useParams();
  const currentPage = paramDetails["*"];
  const toggleSliderbar = () => {
    if (breakpoint !== "desktop") {
      if ($(".header_nav").css("display") === "block")
        $(".header_nav").slideUp("slow");
      else $(".header_nav").slideDown("slow");
    }
  };
  $(document).ready(function () {
    $(".cross i").click(function () {
      $(".header_nav").slideUp("slow");
    });

    //================ Stickey Header ================\\
    const header = document.querySelector(".main_header");
    const toggleClass = "is-sticky";

    window.addEventListener("scroll", () => {
      const currentScroll = window.pageYOffset;
      if (currentScroll > 250) {
        header.classList.add(toggleClass);
      } else {
        header.classList.remove(toggleClass);
      }
    });
  });

  const addActiveClass = (navLink) => {
    return navLink === "home" && currentPage === ""
      ? "nav-link active"
      : !(navLink === "home") && currentPage.includes(navLink)
        ? "nav-link active"
        : "nav-link";
  };
  return (
    <header id="header_sec" className="header_sec">
      <div className="main_header">
        <div className="header">
          <div className="container">
            <div className="row header_row">
              <div className="site_logo">
                <div className="header_logo">
                  <Link to={`/${lang}`}>
                    <ImgTag sourceURL={logoImgSourceURL} altText="Logo Image" />
                  </Link>
                </div>
              </div>
              <div className="navigation">
                <div className="toggler">
                  <i className="fa-solid fa-bars" onClick={toggleSliderbar} />
                </div>
                <nav className={`header_nav`}>
                  <div className="cross">
                    <i
                      className="fa-solid fa-xmark"
                      onClick={toggleSliderbar}
                    />
                  </div>
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <Link
                        to={`/${lang}`}
                        className={addActiveClass("home")}
                        onClick={toggleSliderbar}
                      >
                        {t("Home")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="about-us"
                        onClick={toggleSliderbar}
                        className={addActiveClass("about-us")}
                      >
                        {t("About_Us")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="services"
                        onClick={toggleSliderbar}
                        className={addActiveClass("services")}
                      >
                        {t("Services")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="contact-us"
                        onClick={toggleSliderbar}
                        className={addActiveClass("contact-us")}
                      >
                        {t("Contact_Us")}
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
