import { t } from "i18next";
import React from "react";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import {
  MOBILENUMBER,
  OFFICIALEMAILID,
} from "../../assets/js/Constants/constants";

function PrivacyPolicyPage() {
  return (
    <>
      {/* Breadcrumb Section Start */}
      <Breadcrumb title={t("Privacy_polic")} />
      {/* Breadcrumb Section End */}
      <section id="ss-privacy_policy" className="privacy_policy_page">
        <div className="privacy_policy">
          <div className="container">
            <div className="ss-pp_content_box">
              <div className="ss-pp-intor">
                <p>
                  {t(
                    "TheSamSoft_we_are_committed_to_protecting_visit_our_website"
                  )}{" "}
                  <a href="https://www.thesamsoft.com" target="_blank" rel="noreferrer">
                    <strong>www.thesamsoft.com</strong>
                  </a>{" "}
                  {t("and_use_our_services_related__how_we_will_treat")}
                </p>
              </div>
              <h4 className="ss-pp_title">
                <strong>1. {t("Introduction")}</strong>
              </h4>
              <p className="ss-pp_text">
                {t("We_collect_various_types_of_information__including")}
              </p>
              <ul className="ss-topic_list">
                <li>
                  <h5>{t("Personal_Information")}:</h5>
                  <p className="ss-pp_text">
                    {t(
                      "When_you_interact_with_us_through__you_voluntarily_provide"
                    )}
                  </p>
                </li>
                <li>
                  <h5>{t("Technical_Information")}</h5>
                  <p className="ss-pp_text">
                    {t("We_may_automatically_collect_information__our_website")}
                  </p>
                </li>
                <li>
                  <h5>{t("Usage_Data")}:</h5>
                  <p className="ss-pp_text">
                    {t("We_may_collect_information_that_details_your__metrics")}
                  </p>
                </li>
                <li>
                  <h5>{t("Cookies_and_Tracking_Technologies")}:</h5>
                  <p className="ss-pp_text">
                    {t("We_use_cookies_web_beacons__browser_settings")}
                  </p>
                </li>
              </ul>
              <h4 className="ss-pp_title">
                <strong>2. {t("How_We_Use_Your_Information")}</strong>
              </h4>
              <p className="ss-pp_text">
                {t(
                  "We_use_the_information_we_collect_for_various_purposes_including"
                )}
                :
              </p>
              <ul className="ss-topic_list">
                <li>
                  <h5>{t("To_Provide_Services")}:</h5>
                  <p className="ss-pp_text">
                    {t(
                      "To_deliver_the_services_you_request__other_technical_services"
                    )}
                  </p>
                </li>
                <li>
                  <h5>{t("To_Improve_Our_Services")}:</h5>
                  <p className="ss-pp_text">
                    {t(
                      "We_use_the_information_to__enabling_us_to_improve_andoptimize_our_offerings"
                    )}
                  </p>
                </li>
                <li>
                  <h5>{t("To_Communicate_With_You")}:</h5>
                  <p className="ss-pp_text">
                    {t(
                      "We_may_use_your_personal_information__provided_in_the_emails"
                    )}
                  </p>
                </li>
                <li>
                  <h5>{t("To_Comply_with_Legal_Obligations")}:</h5>
                  <p className="ss-pp_text">
                    {t(
                      "We_may_process_your_information_to_comply__or_that_of_others"
                    )}
                  </p>
                </li>
                <li>
                  <h5>{t("To_Personalize_Your_Experienc")}:</h5>
                  <p className="ss-pp_text">
                    {t("We_may_use_your_information__your_interests")}
                  </p>
                </li>
              </ul>
              <h4 className="ss-pp_title">
                <strong>3. {t("How_We_Share_Your_Information")}</strong>
              </h4>
              <p className="ss-pp_text">
                {t("We_do_not_sell_or_rent__circumstances")}:
              </p>
              <ul className="ss-topic_list">
                <li>
                  <h5>{t("Service_Providers")}:</h5>
                  <p className="ss-pp_text">
                    {t("We_may_share_your__and_marketing_services")}
                  </p>
                </li>
                <li>
                  <h5>{t("Business_Transfers")}:</h5>
                  <p className="ss-pp_text">
                    {t("In_the_event_of_a_merger__that_transaction")}
                  </p>
                </li>
                <li>
                  <h5>{t("Legal_Requirements")}:</h5>
                  <p className="ss-pp_text">
                    {t(
                      "We_may_disclose_your_information_if_required_by_law__safety_of_others"
                    )}
                  </p>
                </li>
                <li>
                  <h5>{t("With_Your_Consent")}:</h5>
                  <p className="ss-pp_text">
                    {t("We_may_share_your_information_with__consent_to_do_so")}
                  </p>
                </li>
              </ul>
              <h4 className="ss-pp_title">
                <strong>4. {t("Data_Security")}</strong>
              </h4>
              <p className="ss-pp_text">
                {t("We_take_the_security_of_your_information__of_your_data")}
              </p>
              <h4 className="ss-pp_title">
                <strong>5. {t("Your_Rights_and_Choices")}</strong>
              </h4>
              <p className="ss-pp_text">
                {t(
                  "You_have_the_following_rights_regarding_your_personal_information"
                )}
                :
              </p>
              <ul className="ss-topic_list">
                <li>
                  <h5>{t("Access_and_Correction")}:</h5>
                  <p className="ss-pp_text">
                    {t(
                      "You_may_request_access_to_the__inaccurate_or_incomplete"
                    )}
                  </p>
                </li>
                <li>
                  <h5>{t("Deletion")}:</h5>
                  <p className="ss-pp_text">
                    {t(
                      "You_may_request_the_deletion_of_your_personal__comply_with_legal_obligations_or_complete_transactions"
                    )}
                  </p>
                </li>
                <li>
                  <h5>{t("Opt_Out_of_Marketing_Communications")}:</h5>
                  <p className="ss-pp_text">
                    {t("You_can_opt_out_of_receiving_marketing__us_directly")}
                  </p>
                </li>
                <li>
                  <h5>{t("Data_Portability")}:</h5>
                  <p className="ss-pp_text">
                    {t("Where_applicable__machine-readable_format")}
                  </p>
                </li>
              </ul>
              <h4 className="ss-pp_title">
                <strong>6. {t("Third_Party_Links")}</strong>
              </h4>
              <p className="ss-pp_text">
                {t("Our_website_may_contain_links__you_visit")}
              </p>
              <h4 className="ss-pp_title">
                <strong>7. {t("International_Data_Transfers")}</strong>
              </h4>
              <p className="ss-pp_text">
                {t(
                  "If_you_are_accessing_our_website_from_outside__to_this_transfer_of_information"
                )}
              </p>
              <h4 className="ss-pp_title">
                <strong>8. {t("Children_Privacy")}</strong>
              </h4>
              <p className="ss-pp_text">
                {t(
                  "Our_services_are_not_intended_for_children_under__from_our_records"
                )}
              </p>
              <h4 className="ss-pp_title">
                <strong>9. {t("Changes_to_This_Privacy_Policy")}</strong>
              </h4>
              <p className="ss-pp_text">
                {t(
                  "We_may_update_this_Privacy_Policy__we_protect_your_information"
                )}
              </p>
              <h4 className="ss-pp_title">
                <strong>9. {t("Contact_Us")}</strong>
              </h4>
              <p className="ss-pp_text">
                {t("if_you_have_any_questions_or__please_contact_us_at")}:
              </p>
              <div className="ss-pp_contact">
                <h2>TheSamSoft LLP</h2>
                <ul className="contact_list">
                  <li className="call">
                    <a href="tel:7410504432">
                      <i className="fa-solid fa-phone" /> {MOBILENUMBER}
                    </a>
                  </li>
                  <li className="mail">
                    <a href="mailto:thesamsoft.official@gmail.com">
                      <i className="fa-solid fa-envelope" /> {OFFICIALEMAILID}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PrivacyPolicyPage;
