import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";

function FooterLanguagesRow() {
  const { lang } = useParams();
  const addActiveClass = (navLink) => {
    return lang === navLink ? "item active" : "item";
  };
  const currentLocation = useLocation().pathname;
  const currentLang = currentLocation.split("/")[1];
  const handleLangNavigate = (langLink) => {
    return currentLocation.replace(currentLang, langLink);
  };
  return (
    <div className="language_row">
      <ul className="list">
        <li className="lang">
          <Link className={addActiveClass("en")} to={handleLangNavigate("en")}>
            English
          </Link>
        </li>
        <li className="lang">
          <Link className={addActiveClass("es")} to={handleLangNavigate("es")}>
            Español
          </Link>
        </li>
        <li className="lang">
          <Link className={addActiveClass("ru")} to={handleLangNavigate("ru")}>
            Русский
          </Link>
        </li>
        <li className="lang">
          <Link className={addActiveClass("de")} to={handleLangNavigate("de")}>
            Deutsch
          </Link>
        </li>
        <li className="lang">
          <Link className={addActiveClass("fr")} to={handleLangNavigate("fr")}>
            Français
          </Link>
        </li>
        <li className="lang">
          <Link className={addActiveClass("ja")} to={handleLangNavigate("ja")}>
            日本語
          </Link>
        </li>
        <li className="lang">
          <Link className={addActiveClass("pt")} to={handleLangNavigate("pt")}>
            Português
          </Link>
        </li>
        <li className="lang">
          <Link className={addActiveClass("tr")} to={handleLangNavigate("tr")}>
            Türkçe
          </Link>
        </li>
        <li className="lang">
          <Link className={addActiveClass("ar")} to={handleLangNavigate("ar")}>
            عربي
          </Link>
        </li>
        <li className="lang">
          <Link className={addActiveClass("hi")} to={handleLangNavigate("hi")}>
            हिंदी
          </Link>
        </li>
        <li className="lang">
          <Link className={addActiveClass("ko")} to={handleLangNavigate("ko")}>
            한국인
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default FooterLanguagesRow;
