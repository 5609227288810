import React from "react";
import { ClipLoader } from "react-spinners";

function SpinnerLoader({ loading, children }) {
  return (
    <>
      {loading ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 1000,
          }}
        >
          <ClipLoader color="#000" loading={loading} size={50} />
        </div>
      ) : (
        <div>{children}</div>
      )}
    </>
  );
}

export default SpinnerLoader;
