import React from "react";
import ImgTag from "../../components/Elements/ImgTag";
import { errorSourceURL } from "../../assets/js/Constants/localImageURL";
import { Link } from "react-router-dom";
import { t } from "i18next";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";

function Error404Page() {
  return (
    <>
      {/* Breadcrumb Section Start */}
      <Breadcrumb title={t("404__Error")} />
      {/* Breadcrumb Section End */}
      <section id="error_page" className="error_page">
        <div className="page_content">
          <div className="container">
            <div className="row">
              <div className="error_img">
                <ImgTag sourceURL={errorSourceURL} altText="Error Image" />
              </div>
              <div className="error_page_content">
                <div className="content">
                  <h2 className="title">{t("Oops_Something_Wrong")}</h2>
                  <h3 className="subtitle">{t("Page_Not_Found")}😵</h3>
                  <p className="text">
                    {t("Oops!_It_looks_like_the_page__to_contact_us")}
                  </p>
                  <div className="back_btn">
                    <Link to="/">{t("Back_To_Home")}</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Contact Page Map Section End */}
    </>
  );
}

export default Error404Page;
