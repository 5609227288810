import React from "react";
import { Route, Routes } from "react-router";
import Layout from "../Common/Layout";
import AboutUsPage from "../../pages/AboutUsPage/AboutUsPage";
import Error404Page from "../../pages/Error404Page/Error404Page";
import HomePage from "../../pages/HomePage/HomePage";
import ServicesPage from "../../pages/ServicesPage/ServicesPage";
import ContactUsPage from "../../pages/ContactUsPage/ContactUsPage";
import DataPage from "../../pages/DataPage/DataPage";
import PrivacyPolicyPage from "../../pages/PrivacyPolicyPage/PrivacyPolicyPage";

function MainRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/">
          <Route index element={<HomePage />} />
          <Route path="about-us" element={<AboutUsPage />} />
          <Route path="services" element={<ServicesPage />}>
            <Route path=":service" element={<ServicesPage />} />
          </Route>
          <Route path="contact-us" element={<ContactUsPage />} />
          <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="aso29" element={<DataPage />} />
          <Route path="*" element={<Error404Page />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default MainRoutes;
