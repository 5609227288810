import React from "react";
import { footerLogoSourceURL } from "../../assets/js/Constants/localImageURL";
import ImgTag from "../Elements/ImgTag";
import { Link } from "react-router-dom";
import LanguageHandler from "../LanguageHandler/LanguageHandler";
import CircleProgressContainer from "../CircleProgressContainer/CircleProgressContainer";
import { t } from "i18next";
import {
  MOBILENUMBER,
  OFFICIALCOMPANYNAME,
  OFFICIALEMAILID,
} from "../../assets/js/Constants/constants";

function Footer() {
  return (
    <>
      <footer id="sam_footer" className="sam_footer">
        <div className="main_footer">
          <div className="container">
            <div className="row footer_row">
              <div className="footer_col about_col">
                <div className="content">
                  <div className="footer_logo">
                    <Link to="/">
                      <ImgTag sourceURL={footerLogoSourceURL} altText="" />
                    </Link>
                  </div>
                  <p className="text">
                    {t(
                      "At_TheSamSoft_we_offer_innovative_WordPress_themes__o_bring_your_vision_to_life"
                    )}
                  </p>
                </div>
              </div>
              <div className="footer_col quick_link">
                <div className="title">
                  <h3>{t("Quick_Links")}</h3>
                </div>
                <ul className="footer_menu list">
                  <li>
                    <Link to="about-us">{t("About_Us")}</Link>
                  </li>
                  <li>
                    <Link to="services">{t("Services")}</Link>
                  </li>
                  <li>
                    <Link to="privacy-policy">{t("Privacy_Policy")}</Link>
                  </li>
                  <li>
                    <Link to="contact-us">{t("Contact_Us")}</Link>
                  </li>
                </ul>
              </div>
              <div className="footer_col">
                <div className="title">
                  <h3>{t("Services")}</h3>
                </div>
                <ul className="footer_menu list">
                  <li>
                    <Link to="services/web-development">
                      {t("Web_Development")}
                    </Link>
                  </li>
                  <li>
                    <Link to="services/app-development">
                      {t("App_Development")}
                    </Link>
                  </li>
                  <li>
                    <Link to="services/web-designing">
                      {t("Web_Designing")}
                    </Link>
                  </li>
                  <li>
                    <Link to="services/graphic-designing">
                      {t("Graphic_Designing")}
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="footer_col">
                <div className="title">
                  <h3>{t("Contact_Us")}</h3>
                </div>
                <ul className="contact_info list">
                  <li className="call">
                    <a href="tel:7410504432">
                      <i className="fa-solid fa-phone" />
                      {MOBILENUMBER}
                    </a>
                  </li>
                  <li className="mail">
                    <a href="mailto:thesamsoft.official@gmail.com">
                      <i className="fa-solid fa-envelope" />
                      {OFFICIALEMAILID}
                    </a>
                  </li>
                </ul>
                <div className="social_media">
                  <h4 className="sub_title">{t("Follow_Us")}:</h4>
                  <ul className="social_icons list">
                    <li className="icon">
                      <a
                        href="https://www.facebook.com/people/Thesamsoftofficial/61560934600014/"
                        target="_blank"
                      >
                        <i className="fa-brands fa-facebook-f" />
                      </a>
                    </li>
                    <li className="icon">
                      <a
                        href="https://www.instagram.com/thesamsoft.official/"
                        target="_blank"
                      >
                        <i className="fa-brands fa-instagram" />
                      </a>
                    </li>
                    <li className="icon">
                      <a
                        href="https://youtube.com/@thesamsoft3?si=yofzk9fVF3Urw0Nx"
                        target="_blank"
                      >
                        <i className="fa-brands fa-youtube" />
                      </a>
                    </li>
                    <li className="icon">
                      <a
                        href="https://www.linkedin.com/company/103322352/admin/analytics/visitors/"
                        target="_blank"
                      >
                        <i className="fa-brands fa-linkedin-in" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <LanguageHandler />
          </div>
        </div>
        <div className="footer_text">
          <div className="container">
            <p className="text text-center">
              {t("©_Copyright")}
              <a href="https://www.thesamsoft.com" target="_blank">
                {OFFICIALCOMPANYNAME}
              </a>
              {t("2024_All_Rights_Reserved")}
            </p>
          </div>
          {/* progress circle Bar */}
          <CircleProgressContainer />
        </div>
      </footer>
      {/* Footer Section End */}
    </>
  );
}

export default Footer;
