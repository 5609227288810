import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enJSON from "./locale/en.json"; // English translations
import arJSON from "./locale/ar.json"; // French translations
import deJSON from "./locale/de.json"; // French translations
import esJSON from "./locale/es.json"; // French translations
import frJSON from "./locale/fr.json"; // French translations
import hiJSON from "./locale/hi.json"; // French translations
import jaJSON from "./locale/ja.json"; // French translations
import koJSON from "./locale/ko.json"; // French translations
import ptJSON from "./locale/pt.json"; // French translations
import ruJSON from "./locale/ru.json"; // French translations
import trJSON from "./locale/tr.json"; // French translations

i18n.use(initReactI18next).init({
  resources: {
    en: { ...enJSON },
    ar: { ...arJSON },
    de: { ...deJSON },
    es: { ...esJSON },
    fr: { ...frJSON },
    hi: { ...hiJSON },
    ja: { ...jaJSON },
    ko: { ...koJSON },
    pt: { ...ptJSON },
    ru: { ...ruJSON },
    tr: { ...trJSON },
  }, // Where we're gonna put translations' files
  lng: "en", // Set the initial language of the App
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
});

export default i18n;
