import "../assets/css/style.css";
import "../assets/css/media.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/js/bootstrap.js";
import "bootstrap/dist/js/bootstrap.min.js";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import LanguageRoutes from "./Routers/LanguageRoutes";
import ScrollToTop from "./ScrollToTop/ScrollToTop";

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path=":lang/*" element={<LanguageRoutes />} />
          <Route path="*" element={<Navigate to="/en" />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
