import { t } from "i18next";
import React from "react";
import { Link, useParams } from "react-router-dom";

function Breadcrumb({ title }) {
  const { lang } = useParams();
  return (
    <section id="ss-breadcrumb_title">
      <div className="sec_body">
        <div className="container">
          <div className="breadcrumb-box">
            <div className="content">
              <h1 className="title">{title}</h1>
              <div className="breadcrumb-list">
                <span>
                  <Link to={`/${lang}`}>
                    <i className="fa-solid fa-house" />
                    {t("Home")}
                  </Link>
                </span>
                <span className="page_title">{title}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Breadcrumb;
