import { t } from "i18next";
import React, { useState } from "react";
import { servicesList } from "../../assets/js/Constants/constants";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function SearchBar() {
  const navigate = useNavigate();
  const [services, setServices] = useState(servicesList);
  const [searchVal, setSearchVal] = useState("");
  const [isDivVisible, setDivVisible] = useState(false);
  const handleFocus = () => {
    setDivVisible(true);
  };
  const handleBlur = () => {
    setTimeout(() => {
      setDivVisible(false);
    }, 300); // Delay to allow the link click to register
  };
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (services[0] === undefined) {
      return toast.error(`No Results Found`);
    }
    const navigateTo = services[0].url;
    console.log("navigateTo", navigateTo);
    navigate(navigateTo);
  };
  function handleSearch(inputVal) {
    setSearchVal(inputVal);
    if (searchVal === "") {
      setServices(servicesList);
      return;
    }
    const filterBySearch = servicesList.filter((item) => {
      if (item.serviceName.toLowerCase().includes(inputVal.toLowerCase())) {
        return item;
      }
    });
    setServices(filterBySearch);
  }
  return (
    <div className="sam_search">
      <div className="serach_inner search_popup">
        <form className="search_form" onSubmit={handleSearchSubmit}>
          <input
            type="search"
            id="search_field"
            className="search_field"
            placeholder={t("Search_Services")}
            value={searchVal}
            onChange={(e) => handleSearch(e.target.value)}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
          <button type="submit" className="search_btn">
            <i className="fa-solid fa-magnifying-glass" />
            {t("Search")}
          </button>
          {isDivVisible && (
            <div className="search_result_list">
              {services.map((service, index) => {
                return (
                  <Link
                    className="test"
                    key={index}
                    onClick={() => console.log("test")}
                    to={`${service.url}`}
                  >
                    {service.serviceName}
                  </Link>
                );
              })}
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default SearchBar;
